<template>
  <div @click="toClosePlay">
    <div class="ts-header">
      <p class="ts-title">开始作业</p>
      <div>
        <i class="el-icon-collection left-icon"></i>
        <span style="margin-left: 4px"
          >{{ exam.title }}（分值:{{ exam.totalScore }}分）</span
        >
        <!-- <van-button
                type="primary"
                @click="handleRefresh"
                style="margin-left: 10px"
                v-show="
                  $refs.recorder &&
                    $refs.recorder.status === 1 &&
                    isNaN($refs.recorder.duration)
                "
                >刷新</van-button
              > -->
      </div>
    </div>
    <div class="paper-content">
      <div class="statistics">
        <div class="header">
          <div>
            <span v-if="limit"
              >倒计时
              <!-- <i class="el-icon-time"></i>  -->
              |
              <span :style="{ color: secondsColor }">{{ timeLabel }}</span
              ><em> | </em></span
            >
            <span v-if="bishiQuestion.length != 12" style="margin-right: 10px"
              >第{{ flow.sort }}/{{ total }}题</span
            >
            <span v-else>检查</span>
          </div>
          <span style="margin-right: 10px">试题数：{{ total }}</span>
        </div>
        <div class="speaking">
          <div
            class="speaking-content"
            @click="addComment2($event)"
            @change="conmentChange2($event)"
            v-html="flow.speakingContentHtml"
          ></div>
        </div>
      </div>
      <RecorderUi
        :isRedo="isRedo"
        :open="open"
        id="recorder"
        ref="recorder"
        :exam="exam"
        :examType="examType"
        @preDown="toPreDown"
        @onClick="changeQuestionContent"
        @handleFrontSumbit="handleFrontSumbit"
        @handleFrontSumbit2="handleFrontSumbit2"
        @onClose="cancel"
        @concatArr="concatArr"
        @handleStudentTaskSubmit22="handleStudentTaskSubmit22"
      ></RecorderUi>
      <!-- <buttonSubmit
              v-else
              ref="recorderbtn"
              :flow="flow"
              :sort="sort"
              :exam="exam"
              :isnoanswer="isnoanswer"
              :loading="isbtnloading"
              @handleFrontSumbit="handleFrontSumbit"
              @changeSort="changeSort"
            >
            </buttonSubmit> -->
    </div>
    <van-dialog
      v-model="loadTypeDia"
      title="做题模式"
      show-confirm-button
      :show-cancel-button="false"
      @confirm="initAgain"
    >
      <van-radio-group class="zq-radio-group" v-model="practiceMode">
        <van-radio :name="1">练习模式</van-radio>
        <van-radio :name="2">考试模式</van-radio>
      </van-radio-group>
    </van-dialog>
    <div class="tihao">
      <span @click="toXt(index)" class="th-item" v-for="(item, index) in 12">{{
        index + 1
      }}</span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { RadioGroup, Radio } from "vant";
Vue.use(RadioGroup);
Vue.use(Radio);
import req from "../util/req";
import RecorderUi from "@/components/RecoderExam";
// import buttonSubmit from '../buttonSubmit/buttonSubmit.vue'
import { Toast } from "vant";
import { mapQuery } from "../util/URLUtils";
// import {
//   studentTaskLoadCache,
//   studentTaskSaveCache,
// } from "@/api/student/student";
// import { studentTesrLoadCache } from "@/api/student/student-test";
let tagIdList = []; //所有大题包含小题的tagid数组
let index = -1,
  xiaotiIdx = -1,
  xiaotiNum = 1,
  xiaotiAnswerArr = [];
let check = false,
  isCheck = false; //是不是在走检查流程
export default {
  name: "Recorder",
  components: {
    RecorderUi,
    // buttonSubmit
  },
  data() {
    return {
      practiceMode: 0, //1练习模式,2考试模式
      loadTypeDia: false,
      query: mapQuery(),
      readyTime: [
        // { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 100,isPreview: true },//prepareSeconds准备时间,speakingSeconds答题时间,previewSeconds预览时间
        // { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        // { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 30 },//开始第一篇短文
        // { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        // { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        // { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 30 },//开始第二篇短文
        // { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        // { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        // { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 40 },//开始长对话
        // { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        // { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        // { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        // { prepareSeconds: 30, speakingSeconds: 15, previewSeconds: 0 },//开始第三部分
        // { prepareSeconds: 30, speakingSeconds: 15, previewSeconds: 0 },
        // { prepareSeconds: 60, speakingSeconds: 30, previewSeconds: 0 },
        // { prepareSeconds: 1, speakingSeconds: 20, previewSeconds: 0 },
        // { prepareSeconds: 1, speakingSeconds: 20, previewSeconds: 0 },
        // { prepareSeconds: 60, speakingSeconds: 60, previewSeconds: 0 },
        // { prepareSeconds: 1, speakingSeconds: 5, previewSeconds: 0 },
        // { prepareSeconds: 1, speakingSeconds: 5, previewSeconds: 0 },
        // { prepareSeconds: 1, speakingSeconds: 5, previewSeconds: 0 },
        // { prepareSeconds: 1, speakingSeconds: 5, previewSeconds: 0 },
        // { prepareSeconds: 30, speakingSeconds: 30, previewSeconds: 0 },
        // { prepareSeconds: 60, speakingSeconds: 60, previewSeconds: 0 }
        {
          prepareSeconds: 0,
          speakingSeconds: 7,
          previewSeconds: 5,
          isPreview: true
        }, //prepareSeconds准备时间,speakingSeconds答题时间,previewSeconds预览时间
        { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 5 }, //开始第一篇短文
        { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 5 }, //开始第二篇短文
        { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 5 }, //开始长对话
        { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        { prepareSeconds: 0, speakingSeconds: 7, previewSeconds: 0 },
        { prepareSeconds: 5, speakingSeconds: 5, previewSeconds: 0 }, //开始第三部分
        { prepareSeconds: 5, speakingSeconds: 5, previewSeconds: 0 },
        { prepareSeconds: 5, speakingSeconds: 5, previewSeconds: 0 },
        { prepareSeconds: 5, speakingSeconds: 5, previewSeconds: 0 },
        { prepareSeconds: 5, speakingSeconds: 5, previewSeconds: 0 },
        { prepareSeconds: 5, speakingSeconds: 5, previewSeconds: 0 },
        { prepareSeconds: 5, speakingSeconds: 5, previewSeconds: 0 },
        { prepareSeconds: 5, speakingSeconds: 5, previewSeconds: 0 },
        { prepareSeconds: 5, speakingSeconds: 5, previewSeconds: 0 },
        { prepareSeconds: 5, speakingSeconds: 5, previewSeconds: 0 },
        { prepareSeconds: 5, speakingSeconds: 5, previewSeconds: 0 },
        { prepareSeconds: 5, speakingSeconds: 5, previewSeconds: 0 }
      ],
      previewtime: 10,
      speakingContentPreview: "",
      isRedo: false,
      flag: false,
      open: false,
      total: 0,
      sort: 0,
      flow: {},
      exam: {},
      limit: false,
      timeLabel: "",
      secondsColor: "white",

      Interval: {},
      examType: "1",
      fHeight: "800",
      fstaticHeight: "727",
      bishiQuestion: [],
      isbtnloading: false,
      isnoanswer: true,
      currentSort: "",
    };
  },
  created() {
    // 监听事件
    window.addEventListener("resize", this.onresize);
    req
      .post("/api/exam/studentApi/userTaskDetail", { id: this.query.id })
      .then((res) => {
        this.redoStatus = false;
        if (res.data.success) {
          const { data } = res.data;
          data.flows = data.speakingQuestions;
          // this.exam = data;
          // if (data.status !== "1" && data.status !== "0") {
          //   Toast("当前作业状态已改变!");
          //   // this.$message({ message: "当前作业状态已改变!", type: "error" });
          //   return;
          // }
          let isRedo =
            data.status == 4 ||
            (data.redoList && data.redoList.length > 0 ? true : false);
          // this.$refs.recorder2.init(data);
          // this.$refs.recorder2.isRedo = isRedo
          //新的听说一体卷-考试模式
          this.init(data);
          this.isRedo = isRedo;
        } else {
          Toast(res.errorDesc);
          // this.$message({ message: res.errorDesc, type: "error" });
        }
      });
  },
  beforeDestroy() {
    // 取消监听事件
    window.removeEventListener("resize", this.onresize);
  },
  mounted() {
    // 获取窗口高度
    const chuangkouClientHeight = document.documentElement.clientHeight;
    this.fHeight = chuangkouClientHeight - 120;
    this.fstaticHeight = chuangkouClientHeight - 193;
  },
  methods: {
    toClosePlay() {
      this.$refs.recorder.toClosePlay();
    },
    toXt(i) {
      const targetElement = document.getElementById("ti" + i);
      if (targetElement) targetElement.scrollIntoView({ behavior: "smooth" });
    },
    toPreDown() {
      this.$set(this.flow, "speakingContentHtml", this.flow.speakingContent);
      // this.flow.speakingContentHtml = this.flow.speakingContent;
      this.$nextTick(() => {
        let radios = document.querySelectorAll('input[type="radio"]');
        for (let index = 0; index < radios.length; index++) {
          radios[index].disabled = false;
        }
      });
    },
    // 合并发送头
    concatArr(questions) {
      //console.log(this.bishiQuestion, '笔试部分头==')
      //console.log(questions, 'questions==')
      let currentArr = [...this.bishiQuestion, ...questions];
      let newArr = [];
      // 去重
      currentArr.forEach((item, index) => {
        if (newArr.length === 0) {
          newArr.push(item);
        } else if (!newArr.find((c) => c.tagId === item.tagId)) {
          newArr.push(item);
        }
      });
      // 排序
      // newArr.sort((a, b) => b.sort - a.sort)
      this.bishiQuestion = [...newArr];

      // console.log(this.bishiQuestion, "当前的头----");
    },
    // 总的提交
    handleStudentTaskSubmit22(questions) {
      this.$refs.recorder.handleStudentTaskSubmit(this.bishiQuestion);
    },
    changeQuestionContent(value) {
      this.flow = this.exam.flows[value];
      //   studentTaskLoadCache({ id: this.exam.id }).then((res) => {
      req
        .post("/api/exam/studentApi/loadCache", {
          id: this.query.id,
        })
        .then((res) => {
          if (res.data.success) {
            const { data } = res.data;
            this.getStudentLoadCache(data);
          } else this.getStudentLoadCache([]);
        });
    },
    handleRefresh() {
      if (this.$refs.recorder) {
        this.$refs.recorder.handleRefresh();
      }
    },
    initTagIdList(flows) {
      tagIdList = [];
      // tagIdList.length = flows.length
      flows.forEach((v) => {
        let tagIdArr = [];
        let matchedString = v.speakingContent.match(/radio\d+/g);
        matchedString = Array.from(new Set(matchedString));
        // matchedString = matchedString.map(v => v.replace('radio', ''))
        tagIdList.push({
          tagId: v.tagId,
          tagIdArr: matchedString,
        });
      });
    },
    initPreview(data) {
      this.open = true;
      this.speakingContentPreview =
        data.speakingQuestions[0].speakingContent +
        data.speakingQuestions[1].speakingContent;
      this.$nextTick(() => {
        let radios = document.querySelectorAll('input[type="radio"]');
        for (let index = 0; index < radios.length; index++) {
          radios[index].disabled = true;
        }
      });
      let tempDsq = setInterval(() => {
        --this.previewtime;
        if (this.previewtime <= 0) {
          clearInterval(tempDsq);
          tempDsq = null;
          this.init(data);
        }
      }, 1000);
    },
    initAgain() {
      if (this.practiceMode <= 0) {
        this.loadTypeDia = false;
        this.$nextTick(() => {
          this.loadTypeDia = true;
          Toast("请选择做题模式");
        });
        return;
      }

      req
        .post("/api/exam/studentApi/saveLoadType", {
          id: this.exam.id,
          practiceMode: this.practiceMode,
        })
        .then((res) => {
          this.loadTypeDia = false;
          this.init(this.exam, this.examType);
          this.$refs.recorder.showPop = false;
        });
    },
    init(data, examType = 1) {
      //examType:1：作业，3：考试
      this.open = true;
      this.exam = data;
      this.initTagIdList(data.flows);
      this.examType = examType.toString();
      this.limit = false;
      this.secondsColor = "white";
      if (data.timeLeft && data.timeLeft > 0) {
        this.limit = true;
        this.getEndTimeUtil(data.timeLeft);
      }

      if (examType == 1) {
        //   studentTaskLoadCache({ id: this.exam.id })
        req
          .post("/api/exam/studentApi/loadCache", {
            id: this.exam.id,
          })
          .then((res) => {
            // const { data } = res.data;
            // setTimeout(() => {
            //   if (data) {
            //     this.getStudentLoadCache(data);
            //   } else this.getStudentLoadCache([]);
            // }, 1000);
            let datas = res.data.data;
            if (!datas) {
              req
                .post("/api/exam/studentApi/getLoadType", {
                  id: this.exam.id,
                })
                .then((res) => {
                  const { data } = res.data;
                  this.practiceMode = data;
                  if (this.practiceMode == 0) this.loadTypeDia = true;
                  else
                    setTimeout(() => {
                      if (datas) {
                        this.getStudentLoadCache(datas);
                      } else this.getStudentLoadCache([]);
                    }, 1000);
                });
            } else {
              setTimeout(() => {
                if (datas) {
                  this.getStudentLoadCache(datas);
                } else this.getStudentLoadCache([]);
              }, 1000);
            }
          });
      }
      if (examType == 3) {
        //   studentTesrLoadCache({ id: this.exam.id })
        req
          .post("/api/exam/studentApi/userTest/loadCache", { id: this.exam.id })
          .then((res) => {
            if (!res.data) {
              req
                .post("/api/exam/studentApi/getLoadType", {
                  id: this.exam.id,
                })
                .then((ress) => {
                  const { data } = ress.data;
                  this.practiceMode = data;
                  if (this.practiceMode == 0) this.loadTypeDia = true;
                  else
                    setTimeout(() => {
                      if (res.success) {
                        const { data } = res;
                        this.getStudentLoadCache(data);
                      } else this.getStudentLoadCache([]);
                    }, 1000);
                });
            } else
              setTimeout(() => {
                if (res.success) {
                  const { data } = res.data;
                  this.getStudentLoadCache(data);
                } else this.getStudentLoadCache([]);
              }, 1000);
          });
      }
    },
    // 监听是否全屏状态
    onresize(event) {
      // 利用屏幕分辨率和window对象的内高度来判断兼容IE
      let winFlag = window.innerHeight === window.screen.height;
      // 利用window全屏标识来判断 -- IE无效
      let isFull = window.fullScreen || document.webkitIsFullScreen;

      if (isFull === undefined) {
        this.isFullscreen = winFlag;
      } else {
        this.isFullscreen = winFlag || isFull;
      }
      // //console.log(winFlag, '是不是全屏===');  // true全屏   false不是全屏
      if (winFlag) {
        this.fHeight = window.screen.height - 80;
        this.fstaticHeight = window.screen.height - 153;
      } else {
        this.fHeight = window.screen.height - 230;
        this.fstaticHeight = window.screen.height - 303;
      }
    },
    handleFrontSumbit2(questions, sort) {
      //非口语题检查提交
      isCheck = true;
      this.handleFrontSumbit(questions, sort);
    },
    // 笔试部分答题提交
    handleFrontSumbit(questions, sort) {
      if (isCheck) {
        this.SaveCacheHandler();
        return;
      }
      try {
        if (!this.bishiQuestion) this.bishiQuestion = [];
        let arr = [];
        this.bishiQuestion.forEach((item) => item && arr.push(item)); // 获取当前已答题的tagId数组
        let currentArr = [...questions, ...arr];
        let newArr = [];
        // 去重
        currentArr.forEach((item, index) => {
          if (newArr.length === 0) {
            newArr.push(item);
          } else if (!newArr.find((c) => c.tagId === item.tagId)) {
            newArr.push(item);
          }
        });
        // 排序
        // newArr.sort((a, b) => b.sort - a.sort)
        this.bishiQuestion = [...newArr];
        let respondNum = 0; //作答小题数
        xiaotiAnswerArr.forEach((v) => {
          if (v) ++respondNum;
        });
        // if (!this.bishiQuestion[this.currentSort] || respondNum < xiaotiNum) {
        //   this.$message({ message: "小题不能不作答", type: "error" });
        //   this.$refs.recorder.clearLoading();
        //   return;
        // } else {

        this.SaveCacheHandler();
        // }
      } catch (error) {
        Toast(error.message);
        this.$refs.recorder.clearLoading();
      }
    },
    conmentChange(event) {
      // console.log(event.target.name, event.target.value);
      const answer = event.target.value;
      const targrtIdName = event.target.name;
      if (
        this.bishiQuestion.findIndex((v) => v.tagId === targrtIdName) !== -1
      ) {
        let index = this.bishiQuestion.findIndex(
          (v) => v.tagId === targrtIdName
        );
        this.bishiQuestion[index] = {
          attachmentId: "",
          tagId: targrtIdName,
          text: answer,
        };
      } else {
        this.bishiQuestion.push({
          attachmentId: "",
          tagId: targrtIdName,
          text: answer,
        });
      }
    },
    conmentChange2(event) {
      if (isCheck) {
        this.updateCheckBishiQuestion(event);
        return;
      }
      // console.log(event.target.name, event.target.value);
      const answer = event.target.value;
      const targrtIdName = event.target.name;
      tagIdList.forEach((v, i) => {
        v.tagIdArr.forEach((item, idx) => {
          if (item === targrtIdName) {
            xiaotiIdx = idx;
            index = i;
            xiaotiNum = v.tagIdArr.length;
            if (xiaotiAnswerArr.length <= 0) {
              xiaotiAnswerArr = new Array(xiaotiNum).fill("");
            }
            xiaotiAnswerArr[xiaotiIdx] = answer;
          }
        });
      });
      if (index >= 0) {
        this.bishiQuestion[index] = {
          attachmentId: "",
          tagId: tagIdList[index].tagId, // targrtIdName,
          text: answer,
        };
        // console.log(this.bishiQuestion);
      } else {
        this.bishiQuestion.push({
          attachmentId: "",
          tagId: tagIdList[index].tagId, // targrtIdName,
          text: answer,
        });
        // console.log(this.bishiQuestion);
      }
    },
    addComment(event) {
      // console.log(event.target.name, event.target.value);
      if (!event.target.value) {
        return false;
      }
      const answer = event.target.value;
      const targrtIdName = event.target.name;
      if (targrtIdName.indexOf("radio") !== -1) {
        if (
          this.bishiQuestion.findIndex((v) => v.tagId === targrtIdName) !== -1
        ) {
          let index = this.bishiQuestion.findIndex(
            (v) => v.tagId === targrtIdName
          );
          this.bishiQuestion[index] = {
            attachmentId: "",
            tagId: targrtIdName,
            text: answer,
          };
        } else {
          this.bishiQuestion.push({
            attachmentId: "",
            tagId: targrtIdName,
            text: answer,
          });
        }
      }
    },
    addComment2(event) {
      if (isCheck) {
        this.updateCheckBishiQuestion(event);
        return;
      }
      // console.log(event.target.name, event.target.value);
      if (!event.target.value) {
        return false;
      }
      const answer = event.target.value;
      const targrtIdName = event.target.name;
      if (targrtIdName.indexOf("radio") !== -1) {
        tagIdList.forEach((v, i) => {
          v.tagIdArr.forEach((item, idx) => {
            if (item === targrtIdName) {
              xiaotiIdx = idx;
              index = i;
              xiaotiNum = v.tagIdArr.length;
              if (xiaotiAnswerArr.length <= 0) {
                xiaotiAnswerArr = new Array(xiaotiNum).fill("");
              }
              xiaotiAnswerArr[xiaotiIdx] = answer;
            }
          });
        });
        if (index >= 0) {
          this.bishiQuestion[index] = {
            attachmentId: "",
            tagId: tagIdList[index].tagId, // targrtIdName,
            text: answer,
          };
          // console.log(this.bishiQuestion);
        } else {
          this.bishiQuestion.push({
            attachmentId: "",
            tagId: tagIdList[index].tagId, // targrtIdName,
            text: answer,
          });
          // console.log(this.bishiQuestion);
        }
      }
    },
    updateCheckBishiQuestion(event) {
      if (!event.target.value) {
        return false;
      }
      const answer = event.target.value;
      const targrtIdName = event.target.name;
      if (targrtIdName.indexOf("radio") !== -1) {
        tagIdList.forEach((v, i) => {
          v.tagIdArr.forEach((item, idx) => {
            if (item === targrtIdName) {
              xiaotiIdx = idx;
              index = i;
              let answerArr = this.bishiQuestion[index].text.split("#");
              answerArr[xiaotiIdx] = answer;
              this.bishiQuestion[index].text = answerArr.join("#");
            }
          });
        });
      }
    },
    /**保存缓存**/
    SaveCacheHandler() {
      // this.bishiQuestion[index].answer = xiaotiAnswerArr.join("#");
      if (this.bishiQuestion.length != 12 || check) {
        let str = "";
        xiaotiAnswerArr.forEach((v) => {
          str += v;
        });
        // console.log(this.bishiQuestion);
        this.bishiQuestion[index].text = xiaotiAnswerArr.join("#");
      } else {
      }
      let param = { answers: this.bishiQuestion, id: this.exam.id };
      if (isCheck) param.check = true;
      // studentTaskSaveCache(param)
      req.post("/api/exam/studentApi/saveCache", param).then((res) => {
        const errorCode = res.data.errorCode;
        const errorDesc = res.data.errorDesc;
        if (errorCode == "100405") {
          Dialog.confirm({
            title: "提示",
            message: "当前用户信息已失效，请重新登录!",
            allowHtml: true,
          }).then(() => {
            wx.miniProgram.redirectTo({
              url: "/pages/select-server/index",
            });
          });
        }
        index = -1;
        xiaotiIdx = -1;
        xiaotiNum = 1;
        xiaotiAnswerArr = [];
        this.$refs.recorder.nextQuestion();
        isCheck = false;
      }); //保存缓存
    },
    /**保存缓存**/
    SaveCacheHandler2() {
      // console.log(this.bishiQuestion);
      // return;
      // studentTaskSaveCache(param).
      req.post("/api/exam/studentApi/saveCache", param).then((res) => {
        index = -1;
        xiaotiIdx = -1;
        xiaotiNum = 1;
        xiaotiAnswerArr = [];
        this.$refs.recorder.nextQuestion();
      }); //保存缓存
    },
    getStudentLoadCache(data) {
      // console.log(data, "data====");
      const self = this;
      let mapData = {};
      let mapTag = {};
      let start = data ? data.length : 0;
      if (data && data.length > 0) {
        check = data[0].check;
        this.practiceMode = data[0].practiceMode;
        if (data.length == 12 && !data[0].check) {
          isCheck = true;
          this.bishiQuestion = JSON.parse(JSON.stringify(data));
        }
        data.forEach((d) => {
          mapData[d.tagId] = d.attachmentId;
        });
      }
      if (this.exam.flows && this.exam.flows.length > 0) {
        this.total = this.exam.flows.length;
        // console.log(this.exam.flows);
        this.exam.flows.forEach((f, index) => {
          mapTag[f.tagId] = f;
          f.complete = false;
          f.urls = [];
          f.sort = index + 1;
          if (mapData[f.tagId] !== undefined) f.complete = true;
          if (f.speakingAttachmentUrl && f.speakingAttachmentUrl !== "") {
            if (f.speakingAttachmentUrl.includes("|")) {
              //type:1,播放，2：停顿，3：准备时间，4：录音时长,5:答题倒计时,6:预览时间,7:检查时间,8:休息时间
              let list = f.speakingAttachmentUrl.split("|");
              list.forEach((u, i) => {
                if (
                  index == 12 &&
                  i == 0 &&
                  data.length == 12 &&
                  !data[0].check
                ) {
                  f.urls.push({
                    type: 7,
                    value: 80,//检查时间
                    // value: 2000,
                    src: "",
                  });
                  f.urls.push({
                    type: 8,
                    value: 30,//休息时间
                    // value: 3,
                    src: "",
                  });
                }
                if (index >= 12 && this.readyTime[index].prepareSeconds > 0)
                  f.urls.push({
                    type: 3,
                    value: this.readyTime[index].prepareSeconds,
                    src: "",
                  });
                let item = { type: 1, value: 0, src: "" };
                if (u.indexOf("$") > -1) {
                  item.type = 2;
                  item.value = parseInt(u.substr(1, u.length));
                } else {
                  item.src = u;
                }
                f.urls.push(item);
                if (
                  i == 0 &&
                  index <= 11 &&
                  this.readyTime[index].previewSeconds > 0
                )
                  f.urls.push({
                    type: 6,
                    value: this.readyTime[index].previewSeconds,
                    src: "",
                  });
                if (
                  !(
                    (index == 0 || index == 2 || index == 5 || index == 8) &&
                    i == 0
                  ) &&
                  this.readyTime[index].speakingSeconds > 0
                )
                  f.urls.push({
                    type: 5,
                    value: this.readyTime[index].speakingSeconds,
                    src: "",
                  });
                if (index >= 12 && this.readyTime[index].speakingSeconds > 0)
                  f.urls.push({
                    type: 4,
                    value: this.readyTime[index].speakingSeconds,
                    src: "",
                  });
              });
            } else {
              if (index == 12 && data.length == 12 && !data[0].check) {
                f.urls.push({
                  type: 7,
                  value: 80,//检查时间
                  // value: 2000,
                  src: "",
                });
                f.urls.push({
                  type: 8,
                  value: 30,//休息时间
                  // value: 3,
                  src: "",
                });
              }
              if (index >= 12 && this.readyTime[index].prepareSeconds > 0)
                f.urls.push({
                  type: 3,
                  value: this.readyTime[index].prepareSeconds,
                  src: "",
                });
              f.urls.push({ type: 1, value: 0, src: f.speakingAttachmentUrl });
              if (index <= 11 && this.readyTime[index].speakingSeconds > 0)
                f.urls.push({
                  type: 5,
                  value: this.readyTime[index].speakingSeconds,
                  src: "",
                });
              if (index >= 12 && this.readyTime[index].speakingSeconds > 0)
                f.urls.push({
                  type: 4,
                  value: this.readyTime[index].speakingSeconds,
                  src: "",
                });
            }
          } else {
            if (index == 12) {
              f.urls.push({
                type: 7,
                // value: 80,
                value: 15,
                src: "",
              });
              f.urls.push({
                type: 8,
                // value: 30,
                value: 3,
                src: "",
              });
            }
            if (index >= 12 && this.readyTime[index].prepareSeconds > 0)
              f.urls.push({
                type: 3,
                value: this.readyTime[index].prepareSeconds,
                src: "",
              });
            // f.urls.push({ type: 1, value: 0, src: f.speakingAttachmentUrl });
            if (index <= 11 && this.readyTime[index].speakingSeconds > 0)
              f.urls.push({
                type: 5,
                value: this.readyTime[index].speakingSeconds,
                src: "",
              });
            if (index >= 12 && this.readyTime[index].speakingSeconds > 0)
              f.urls.push({
                type: 4,
                value: this.readyTime[index].speakingSeconds,
                src: "",
              });
          }
        });
        this.currentSort = start;
        this.flow = this.exam.flows[start];
        if (start == 0) {
          this.flow.speakingContentHtmlPre =
            this.flow.speakingContent +
            this.exam.flows[start + 1].speakingContent; //前两道大题的预览内容
          // this.flow.speakingContentHtml = this.flow.speakingContentHtmlPre;
          this.$set(
            this.flow,
            "speakingContentHtml",
            this.flow.speakingContentHtmlPre
          );
          this.$nextTick(() => {
            let radios = document.querySelectorAll('input[type="radio"]');
            for (let index = 0; index < radios.length; index++) {
              radios[index].disabled = true;
            }
          });
        } else if (start == 12 && data.length == 12 && !data[0].check) {
          // this.flow.speakingContentHtmlPre =
          //   this.exam.flows[0].speakingContent +
          //   this.exam.flows[1].speakingContent +
          //   this.exam.flows[2].speakingContent +
          //   this.exam.flows[3].speakingContent +
          //   this.exam.flows[4].speakingContent +
          //   this.exam.flows[5].speakingContent +
          //   this.exam.flows[6].speakingContent +
          //   this.exam.flows[7].speakingContent +
          //   this.exam.flows[8].speakingContent +
          //   this.exam.flows[9].speakingContent +
          //   this.exam.flows[10].speakingContent +
          //   this.exam.flows[11].speakingContent; //前12道大题

          this.flow.speakingContentHtmlPre = "";
          this.exam.flows.forEach((v, i) => {
            if (i < 12) {
              let speakingContent =
                `<p id='ti${i}'` +
                v.speakingContent.substring(2, v.speakingContent.length);
              this.flow.speakingContentHtmlPre += speakingContent;
            }
          });

          // this.flow.speakingContentHtml = this.flow.speakingContentHtmlPre;
          this.$set(
            this.flow,
            "speakingContentHtml",
            this.flow.speakingContentHtmlPre
          );
          this.$nextTick(() => {
            for (let i = 0; i < 12; i++) {
              if (i == 0 || i == 1) {
                let answer = data[i].text.split("#");
                let tagIdList = [data[i].tagId, "", "", "", ""];
                tagIdList.forEach((item, index) => {
                  if (index < 4) {
                    tagIdList[index + 1] = item.replace(/\d+/g, function(
                      match
                    ) {
                      return String(Number(match) + 1);
                    });
                  }
                  if (answer[index])
                    document.querySelector(
                      `input[type="radio"][name=${item}][value=${answer[index]}]`
                    ).checked = true;
                });
              } else {
                let answer = data[i].text;
                if (answer)
                  document.querySelector(
                    `input[type="radio"][name=${data[i].tagId}][value=${answer}]`
                  ).checked = true;
              }
            }
          });
        } else {
          // this.flow.speakingContentHtml = this.flow.speakingContent;
          this.$set(
            this.flow,
            "speakingContentHtml",
            this.flow.speakingContent
          );
        }
        // console.log(tagIdList);
        // console.log(this.flow);
        tagIdList.forEach((v, i) => {
          if (v.tagIdArr.length > 0) {
            v.tagIdArr.forEach((item, idx) => {
              if (item === this.flow.tagId) {
                index = i;
                xiaotiNum = v.tagIdArr.length;
                if (xiaotiAnswerArr.length <= 0) {
                  xiaotiAnswerArr = new Array(xiaotiNum).fill("");
                }
              }
            });
          } else {
            if (v.tagId === this.flow.tagId) {
              index = i;
              xiaotiNum = v.tagIdArr.length;
              if (xiaotiAnswerArr.length <= 0) {
                xiaotiAnswerArr = new Array(xiaotiNum).fill("");
              }
            }
          }
        });
        if (index == 12 && data.length == 12 && !data[0].check) {
        } else {
          if (index >= 0) {
            // let index = this.bishiQuestion.findIndex(v => v.tagId === targrtIdName);
            this.bishiQuestion[index] = {
              attachmentId: "",
              tagId: tagIdList[index].tagId, // targrtIdName,
              text: "",
            };
          } else {
            this.bishiQuestion.push({
              attachmentId: "",
              tagId: tagIdList[index].tagId, // targrtIdName,
              text: "",
            });
          }
        }

        setTimeout(() => {
          self.$refs.recorder.init(-1, start, data, this.practiceMode);
        }, 200);
      }
    },
    // getStudentLoadCache(data) {
    //   const self = this;
    //   let mapData = {};
    //   let mapTag = {};
    //   let start = data ? data.length : 0;
    //   if (data && data.length > 0) {
    //     check = data[0].check;
    //     if (data.length == 5 && !data[0].check) {
    //       isCheck = true;
    //       this.bishiQuestion = JSON.parse(JSON.stringify(data));
    //     }
    //     data.forEach((d) => {
    //       mapData[d.tagId] = d.attachmentId;
    //     });
    //   }
    //   if (this.exam.flows && this.exam.flows.length > 0) {
    //     this.total = this.exam.flows.length;
    //     this.exam.flows.forEach((f, index) => {
    //       mapTag[f.tagId] = f;
    //       f.complete = false;
    //       f.urls = [];
    //       f.sort = index + 1;
    //       if (mapData[f.tagId] !== undefined) f.complete = true;
    //       if (f.speakingAttachmentUrl && f.speakingAttachmentUrl !== "") {
    //         if (f.speakingAttachmentUrl.includes("|")) {
    //           //type:1,播放，2：停顿，3：准备时间，4：录音时长,5:答题倒计时,6:预览时间,7:检查时间,8:休息时间
    //           let list = f.speakingAttachmentUrl.split("|");
    //           list.forEach((u, i) => {
    //             if (
    //               index == 5 &&
    //               i == 0 &&
    //               data.length == 5 &&
    //               !data[0].check
    //             ) {
    //               f.urls.push({
    //                 type: 7,
    //                 // value: 80,//检查时间
    //                 value: 15,
    //                 src: "",
    //               });
    //               f.urls.push({
    //                 type: 8,
    //                 // value: 30,//休息时间
    //                 value: 3,
    //                 src: "",
    //               });
    //             }
    //             if (index >= 5 && this.readyTime[index].prepareSeconds > 0)
    //               f.urls.push({
    //                 type: 3,
    //                 value: this.readyTime[index].prepareSeconds,
    //                 src: "",
    //               });
    //             let item = { type: 1, value: 0, src: "" };
    //             if (u.indexOf("$") > -1) {
    //               item.type = 2;
    //               item.value = parseInt(u.substr(1, u.length));
    //             } else {
    //               item.src = u;
    //             }
    //             f.urls.push(item);
    //             if (
    //               i == 0 &&
    //               index <= 4 &&
    //               this.readyTime[index].previewSeconds > 0
    //             )
    //               f.urls.push({
    //                 type: 6,
    //                 value: this.readyTime[index].previewSeconds,
    //                 src: "",
    //               });
    //             if (
    //               (((index == 0 || index == 2 || index == 4) && i != 0) ||
    //                 (index >= 1 && index <= 4)) &&
    //               this.readyTime[index].speakingSeconds > 0
    //             )
    //               f.urls.push({
    //                 type: 5,
    //                 value: this.readyTime[index].speakingSeconds,
    //                 src: "",
    //               });
    //             if (index >= 5 && this.readyTime[index].speakingSeconds > 0)
    //               f.urls.push({
    //                 type: 4,
    //                 value: this.readyTime[index].speakingSeconds,
    //                 src: "",
    //               });
    //           });
    //         } else {
    //           if (index == 5 && data.length == 5 && !data[0].check) {
    //             f.urls.push({
    //               type: 7,
    //               // value: 80,//检查时间
    //               value: 15,
    //               src: "",
    //             });
    //             f.urls.push({
    //               type: 8,
    //               // value: 30,//休息时间
    //               value: 3,
    //               src: "",
    //             });
    //           }
    //           if (index >= 5 && this.readyTime[index].prepareSeconds > 0)
    //             f.urls.push({
    //               type: 3,
    //               value: this.readyTime[index].prepareSeconds,
    //               src: "",
    //             });
    //           f.urls.push({ type: 1, value: 0, src: f.speakingAttachmentUrl });
    //           if (index <= 4 && this.readyTime[index].speakingSeconds > 0)
    //             f.urls.push({
    //               type: 3,
    //               value: this.readyTime[index].speakingSeconds,
    //               src: "",
    //             });
    //           if (index >= 5 && this.readyTime[index].speakingSeconds > 0)
    //             f.urls.push({
    //               type: 4,
    //               value: this.readyTime[index].speakingSeconds,
    //               src: "",
    //             });
    //         }
    //       } else {
    //         if (index == 5) {
    //           f.urls.push({
    //             type: 7,
    //             // value: 80,
    //             value: 15,
    //             src: "",
    //           });
    //           f.urls.push({
    //             type: 8,
    //             // value: 30,
    //             value: 3,
    //             src: "",
    //           });
    //         }
    //         if (index >= 5 && this.readyTime[index].prepareSeconds > 0)
    //           f.urls.push({
    //             type: 3,
    //             value: this.readyTime[index].prepareSeconds,
    //             src: "",
    //           });
    //         if (index <= 4 && this.readyTime[index].speakingSeconds > 0)
    //           f.urls.push({
    //             type: 3,
    //             value: this.readyTime[index].speakingSeconds,
    //             src: "",
    //           });
    //         if (index >= 5 && this.readyTime[index].speakingSeconds > 0)
    //           f.urls.push({
    //             type: 4,
    //             value: this.readyTime[index].speakingSeconds,
    //             src: "",
    //           });
    //       }
    //     });
    //     this.currentSort = start;
    //     this.flow = this.exam.flows[start];
    //     if (start == 0) {
    //       this.flow.speakingContentHtmlPre =
    //         this.flow.speakingContent +
    //         this.exam.flows[start + 1].speakingContent; //前两道大题的预览内容
    //       this.flow.speakingContentHtml = this.flow.speakingContentHtmlPre;
    //       this.$nextTick(() => {
    //         let radios = document.querySelectorAll('input[type="radio"]');
    //         for (let index = 0; index < radios.length; index++) {
    //           radios[index].disabled = true;
    //         }
    //       });
    //     } else if (start == 5 && data.length == 5 && !data[0].check) {
    //       this.flow.speakingContentHtmlPre =
    //         this.exam.flows[0].speakingContent +
    //         this.exam.flows[1].speakingContent +
    //         this.exam.flows[2].speakingContent +
    //         this.exam.flows[3].speakingContent +
    //         this.exam.flows[4].speakingContent; //前5道大题
    //       this.flow.speakingContentHtml = this.flow.speakingContentHtmlPre;
    //     } else this.flow.speakingContentHtml = this.flow.speakingContent;
    //     tagIdList.forEach((v, i) => {
    //       if (v.tagIdArr.length > 0) {
    //         v.tagIdArr.forEach((item, idx) => {
    //           if (item === this.flow.tagId) {
    //             index = i;
    //             xiaotiNum = v.tagIdArr.length;
    //             if (xiaotiAnswerArr.length <= 0) {
    //               xiaotiAnswerArr = new Array(xiaotiNum).fill("");
    //             }
    //           }
    //         });
    //       } else {
    //         if (v.tagId === this.flow.tagId) {
    //           index = i;
    //           xiaotiNum = v.tagIdArr.length;
    //           if (xiaotiAnswerArr.length <= 0) {
    //             xiaotiAnswerArr = new Array(xiaotiNum).fill("");
    //           }
    //         }
    //       }
    //     });
    //     if (index == 5 && data.length == 5 && !data[0].check) {
    //     } else {
    //       if (index >= 0) {
    //         // let index = this.bishiQuestion.findIndex(v => v.tagId === targrtIdName);
    //         this.bishiQuestion[index] = {
    //           attachmentId: "",
    //           tagId: tagIdList[index].tagId, // targrtIdName,
    //           text: "",
    //         };
    //       } else {
    //         this.bishiQuestion.push({
    //           attachmentId: "",
    //           tagId: tagIdList[index].tagId, // targrtIdName,
    //           text: "",
    //         });
    //       }
    //     }

    //     setTimeout(() => {
    //       self.$refs.recorder.init(-1, start, data);
    //     }, 200);
    //   }
    // },

    /**计算时间**/
    getEndTimeUtil(maxtime) {
      const self = this;
      this.Interval = setInterval(() => {
        if (maxtime < 30) self.secondsColor = "red";
        if (maxtime > 0) {
          let minutes = Math.floor(maxtime / 60);
          let seconds = Math.floor(maxtime % 60);
          self.timeLabel = `${minutes}分${seconds}秒`;
          --maxtime;
        } else {
          clearInterval(self.Interval);
          // self.$refs.recorder.handleStudentTaskSubmit();
          self.cancel();
        }
      }, 1000);
    },

    cancel() {
      clearInterval(this.Interval);
      this.sort = 0; // sort置0
      this.currentSort = 0;
      this.bishiQuestion = []; // 笔试部分置为[]
      this.$refs.recorder.tocanceldingshiqi(); // 清除所有定时器
      console.log(123);
      wx.miniProgram.redirectTo({
        url: "/pages/common-index/index",
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.paper-content {
  width: 100%;
  height: calc(100vh - 80px);
  margin: 0 auto;
  position: relative;
  border: 1px solid #cccccc;
  .statistics {
    height: 568px;
    overflow: auto;
    height: calc(100vh - 142px);
    position: relative;
    .header {
      box-sizing: border-box;
      height: 40px;
      color: white;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgba(0, 160, 233, 0.6);
      padding: 0 15px;
      .left-icon {
        line-height: 40px;
        width: 40px;
        text-align: center;
        background-color: #0a6cd6;
      }
    }
    .content {
      width: 100%;
      padding: 10px;
    }
    .card {
      margin: 10px;
      border: 1px solid #f3f3f3;
      .hard {
        height: 40px;
        background-color: #f3f3f3;
        span {
          line-height: 40px;
          margin-left: 20px;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: 60px;
    // margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    .label {
      font-size: 22px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
    }
    .two {
      display: flex;
      justify-content: center;
    }
    .three {
      .right-div {
        padding: 1px;
        border-radius: 2px;
        border: 1px dashed #666666;
        .second-div {
          width: 300px;
          margin: 0 2px;
          box-sizing: border-box;
          background-color: white;
        }
      }
    }
  }
  p,
  span {
    line-height: 1.5em !important;
    font-weight: normal;
    font-size: medium !important;
  }
  .MsoNormal {
    // font-size: medium !important;
    font-size: 0.42rem;
  }
  .ts-title {
    padding: 10px;
  }
}
.speaking {
  position: relative;
  .speaking-content {
    white-space: normal;
    word-break: keep-all;
    padding: 0 15px;
    .speaking-content >>> p {
      line-height: 32px;
      white-space: normal;
      word-break: keep-all;
    }
    .speaking-content >>> span {
      line-height: 28px;
      white-space: normal;
      word-break: keep-all;
    }
  }
}
.preview-time {
  text-align: center;
  color: #f56c6c;
}

.ts-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  box-sizing: border-box;
}
.zq-radio-group {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
}
.tihao {
  width: 7vw;
  height: 60vh;
  position: fixed;
  top: 20vh;
  right: 0;
  // background-color: #f56c6c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .th-item {
    flex: 1;
  }
}
</style>
